<template>
  <div class="coupon-detail-content">
    <div class="" v-if="isLoading">
      <OtherLoading variant="dark" />
    </div>
    <div
      class="p-4 coupon-detail d-flex align-items-center flex-column pt-5"
      :style="styleObject"
      v-else
    >
      <div class="text-center my-4">
        <b-img-lazy
          v-if="coupon.id == 0"
          v-bind="mainProps"
          :src="require('@/assets/images/point_counter.jpeg')"
          class="center-cropped"
          alt="Image 1"
        ></b-img-lazy>
        <b-img-lazy
          v-if="coupon.id == 1"
          v-bind="mainProps"
          :src="require('@/assets/images/point_shop.jpeg')"
          class="center-cropped"
          alt="Image 1"
        ></b-img-lazy>
      </div>
      <div :class="['px-2 my-auto']">
        <template v-if="coupon.id == 0 || coupon.id == 1">
          <div class="font-weight-bold">
            {{ coupon.name }}
          </div>
          <div class="mt-2">
            <p class="font-weight-bold">การสะสมคะแนน / แลกคะแนน</p>
            <div class="f-size-14 pl-3">
              <p class="mb-0">
                - เมื่อซื้อสินค้าจากแบรนด์บริษัท ธนูลักษณ์ จำกัด (มหาชน)
                และบริษัท ไอ ซีซี.ซี. อินเตอร์เนชั่นแนล ณ
                เคาน์เตอร์แบรนด์และร้านค้าที่ร่วมรายการ
              </p>
              <p class="mb-0">
                - ทุกการใช้จ่าย 25 บาท = 1 คะแนน โดยคิดจากยอดสุทธิ
              </p>
              <p class="mb-0">
                - สมาชิกต้องแจ้งเบอร์โทรศัพท์ หรือแสดง barcode จาก His & Her
                Line Business เพื่อรับสิทธิ์ทุกครั้งก่อนชำระสินค้า
                ไม่สามารถแจ้งสิทธิ์ย้อนหลังได้
              </p>
              <p class="mb-0">
                - การสะสมคะแนนต้องทำการบันทึกในระบบ
                ภายในวันที่ซื้อสินค้าเท่านั้น
                และคะแนนจะแสดงในระบบสมาชิกในวันถัดไป
              </p>
              <p class="mb-0">
                - เมื่อซื้อสินค้าและสะสมคะแนนตลอดเดือนเกิด รับคะแนนเพิ่ม 3 เท่า
              </p>
              <p class="mb-0">
                - คะแนนสะสมมีอายุ 3 ปี
                คะแนนสะสมส่วนที่หมดอายุจะถูกตัดออกจากระบบทุกวันที่ 31 ธันวาคม
                เวลา 24.00 น.
              </p>
              <p class="mb-0">
                - กรณีสมาชิกคืนสินค้า จะถูกหักคะแนนออกไปตามจำนวนคะแนนที่ได้รับ
              </p>
              <p class="mb-0">
                - บริษัทฯ ขอสงวนสิทธิ์ในการเปลี่ยนแปลง หรือยกเลิก จำนวนคะแนน
                วิธีการคิดคะแนน และการสะสมคะแนน โดยมิต้องแจ้งให้ทราบล่วงหน้า
              </p>
            </div>
            <p class="my-2 font-weight-bold">ตรวจสอบคะแนนสะสม</p>
            <div class="pl-2 f-size-14">
              <p class="mb-0">
                - ตรวจสอบคะแนนสะสมผ่านทาง His & Her Line Business
              </p>
            </div>
            <p class="my-2 font-weight-bold">หมายเหตุ</p>
            <div class="pl-2 f-size-14">
              <p class="mb-0">- คะแนนมีอายุ 3 ปี นับตั้งแต่วันที่สมัคร</p>
              <p class="mb-0">
                - กรณีสมาชิกคืนสินค้า จะถูกหักคะแนนออกไปตามจำนวนคะแนนที่ได้รับ
              </p>
              <p class="mb-0">- คะแนนที่ได้รับจะคิดจากยอดสุทธิ</p>
              <p class="mb-0">
                - สมาชิกต้องแจ้งเบอร์โทรศัพท์หรือแสดง barcode
                เพื่อรับสิทธิ์ทุกครั้ง ไม่สามารถแจ้งสิทธิ์ย้อนหลังได้
              </p>
              <p class="mb-0">
                - สมาชิกต้องแจ้งเบอร์โทรศัพท์หรือแสดง barcode
                เพื่อรับสิทธิ์ทุกครั้ง ไม่สามารถแจ้งสิทธิ์ย้อนหลังได้
              </p>
              <p class="mb-0">- คะแนนจะแสดงในระบบสมาชิกในวันถัดไป</p>
            </div>
            <p class="my-2 font-weight-bold">การแลกคะแนน</p>
            <div class="pl-2 f-size-14">
              <p class="mb-0">
                - สมาชิกสามารถแลกคะแนนผ่าน His & Her Line Business เท่านั้น
              </p>
              <p class="mb-0">- การแลกคะแนนแบ่งออกเป็น 2 แบบ ได้แก่</p>
              <p class="mb-0">
                1. ใช้ในเคาน์เตอร์แบรนด์ เริ่มต้นที่ 750คะแนน
                แลกรับบัตรกำนัลมูลค่า 100 บาท
              </p>
              <p class="mb-0">
                2. ใช้ในร้านค้าของแบรนด์ (shop) เริ่มต้นที่ 500คะแนน
                แลกรับบัตรกำนัลมูลค่า 50 บาท
              </p>
            </div>
          </div>
          <div class="f-size-14 font-weight-bold mt-1">
            <p class="mb-1">
              Valid From {{ coupon.startTime }} - {{ coupon.endTime }}
            </p>
          </div>
        </template>
        <template v-else>
          <div class="font-weight-bold">
            {{ coupon.name }}
          </div>
          <div>
            <p class="mb-1">{{ coupon.description }}</p>
          </div>
        </template>
      </div>
      <div
        class="my-auto pt-2 text-center"
        v-if="$route.params.couponUser == 'user'"
      >
        <b-button
          variant="light"
          class="font-weight-bold py-2 px-4"
          @click="onCpnfirmToRedeem(coupon.name)"
          >REDEEM CODE</b-button
        >
      </div>
      <div v-else class="my-auto pt-2 text-center">
        <div class="mt-2 f-size-14 mb-2">
          <u @click="onCpnfirmToRedeemForStaff(coupon.name)">STAFF USE</u>
        </div>
      </div>
      <ModalConfirm
        ref="redeemConfirmModal"
        :header="confirmRedeem.header"
        :text="confirmRedeem.text"
        :subText="confirmRedeem.subText"
        :button="confirmRedeem.button"
        @handler="onConfirmRedeemResponse"
      />
      <ModalAlert ref="modalAlert" :text="successMessage" />
    </div>
  </div>
</template>

<script>
import ModalConfirm from "@/components/alert-modal/ModalConfirm.vue";
import ModalAlert from "@/components/alert-modal/ModalAlert.vue";
import OtherLoading from "@/components/other/OtherLoading";
import liff from "@line/liff";
export default {
  components: {
    ModalConfirm,
    OtherLoading,
    ModalAlert
  },
  data() {
    return {
      mainProps: {
        center: true,
        fluidGrow: true,
        blank: true,
        blankColor: "white",
        width: 600,
        height: 600,
        class: ""
      },
      isLoading: true,
      successMessage: "ยืนยันการแลกสำเร็จ",
      confirmRedeem: {
        header: "ยืนยันการแลก ?",
        text: "text",
        subText: "subText",
        button: {
          true: {
            class: ["btn-danger"],
            msg: "ยืนยัน"
          },
          false: {
            class: ["btn-secondary"],
            msg: "ยกเลิก"
          }
        }
      },
      styleObject: {
        "background-color": "white",
        color: "black"
      },
      coupon: null,
      dataList: [
        {
          id: 0,
          name: "ช้อปปั๊ป รับพอยท์",
          description:
            "a coupon is a ticket or document that can be redeemed for a financial discount or rebate when purchasing a product. ",
          startTime: "01 Jul",
          endTime: "31 Jul",
          frontColor: "white",
          backgroundColour: "#114B5F",
          imageUrl: "@/assets/images/default-image.png",
          urlKey: "Coupon-A"
        },
        {
          id: 1,
          name: "ช้อปปั๊ป รับพอยท์",
          description:
            "a coupon is a ticket or document that can be redeemed for a financial discount or rebate when purchasing a product.",
          startTime: "01 Jul",
          endTime: "31 Jul",
          frontColor: "white",
          backgroundColour: "#173F5F",
          imageUrl: "@/assets/images/default-image.png",
          urlKey: "Coupon-B"
        },
        {
          id: 2,
          name: "Coupon C",
          description:
            "a coupon is a ticket or document that can be redeemed for a financial discount or rebate when purchasing a product.",
          startTime: "01 Jul",
          endTime: "31 Jul",
          frontColor: "white",
          backgroundColour: "#20639B",
          imageUrl: "@/assets/images/default-image.png",
          urlKey: "Coupon-C"
        },
        {
          id: 3,
          name: "Coupon D",
          description:
            "a coupon is a ticket or document that can be redeemed for a financial discount or rebate when purchasing a product.",
          startTime: "01 Jul",
          endTime: "31 Jul",
          frontColor: "white",
          backgroundColour: "#3CAEA3",
          imageUrl: "@/assets/images/default-image.png",
          urlKey: "Coupon-D"
        },
        {
          id: 4,
          name: "Coupon E",
          description:
            "a coupon is a ticket or document that can be redeemed for a financial discount or rebate when purchasing a product.",
          startTime: "01 Jul",
          endTime: "31 Jul",
          frontColor: "black",
          backgroundColour: "#F6D55C",
          imageUrl: "@/assets/images/default-image.png",
          urlKey: "Coupon-E"
        },
        {
          id: 5,
          name: "Coupon F",
          description:
            "a coupon is a ticket or document that can be redeemed for a financial discount or rebate when purchasing a product.",
          startTime: "01 Jul",
          endTime: "31 Jul",
          frontColor: "black",
          backgroundColour: "#EE553B",
          imageUrl: "@/assets/images/default-image.png",
          urlKey: "Coupon-F"
        },
        {
          id: 6,
          name: "Coupon G",
          description:
            "a coupon is a ticket or document that can be redeemed for a financial discount or rebate when purchasing a product.",
          startTime: "01 Jul",
          endTime: "31 Jul",
          frontColor: "white",
          backgroundColour: "#2A9D8F",
          imageUrl: "@/assets/images/default-image.png",
          urlKey: "Coupon-G"
        },
        {
          id: 6,
          name: "Coupon H",
          description:
            "a coupon is a ticket or document that can be redeemed for a financial discount or rebate when purchasing a product.",
          startTime: "01 Jul",
          endTime: "31 Jul",
          frontColor: "white",
          backgroundColour: "#f4A261",
          imageUrl: "@/assets/images/default-image.png",
          urlKey: "Coupon-H"
        }
      ]
    };
  },
  created: async function () {
    this.getCouponDetail();
    setTimeout(() => {
      this.isLoading = false;
    }, 500);
    this.$store.dispatch("setIsRegister", false);
  },
  methods: {
    onCpnfirmToRedeemForStaff() {
      this.confirmRedeem = {
        header: `กรุณาส่งให้พนักงานเป็นกดเท่านั้น ?`,
        text: "",
        subText: "",
        button: {
          true: {
            class: ["btn-danger"],
            msg: "ยืนยัน"
          },
          false: {
            class: ["btn-secondary"],
            msg: "ยกเลิก"
          }
        }
      };
      this.$refs.redeemConfirmModal.show();
    },
    onCpnfirmToRedeem(displayName) {
      this.confirmRedeem = {
        header: `ยืนยันการแลก "${displayName}" ?`,
        text: "",
        subText: "",
        button: {
          true: {
            class: ["btn-danger"],
            msg: "ยืนยัน"
          },
          false: {
            class: ["btn-secondary"],
            msg: "ยกเลิก"
          }
        }
      };
      this.$refs.redeemConfirmModal.show();
    },
    onConfirmRedeemResponse(value) {
      if (value) {
        if (this.$liff.isInClient()) {
          if (this.$route.params.couponUser == "user") {
            this.onSendMessageToChat();
          } else {
            this.$liff.closeWindow();
          }
        } else {
          this.$refs.modalAlert.show();
        }
      }
    },
    onSendMessageToChat() {
      this.$liff
        .sendMessages([
          {
            type: "template",
            altText: "แลกแต้มเรียบร้อย",
            template: {
              type: "buttons",
              text: "แลกแต้มเรียบร้อย",
              actions: [
                {
                  type: "uri",
                  label: "View Coupon",
                  uri: `${this.$liffUrl}/coupon/staff/${this.$route.params.couponKey}`
                }
              ]
            }
          }
        ])
        .then(() => {
          liff.closeWindow();
        })
        .catch(err => {
          console.log("error", err);
        });
    },
    onCloseLiffApp() {
      if (this.$liff.isInClient()) {
        this.$liff.closeWindow();
      } else {
        this.$refs.modalAlert.show();
      }
    },
    getCouponDetail: async function () {
      this.dataList.find(el => {
        if (el.urlKey == this.$route.params.couponKey) {
          this.coupon = el;
          this.styleObject = {
            "background-color": el.backgroundColour,
            color: el.frontColor
          };
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.coupon-detail {
  min-height: 100vh;
}
.coupon-detail-content {
  height: 100vh;
}
.center-cropped {
  width: 90% !important;
  height: auto;
  border-radius: 5px;
}
.center-cropped2 {
  width: 90% !important;
  height: auto;
  border-radius: 5px;
}
.qr-image {
  width: 65%;
  height: auto;
  border-radius: 5px;
}
</style>
